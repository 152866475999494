import Layout from "../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const MediaPage = () => {
 

  return (
    <Layout >
      <Seo title="Media" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading mb-10">Media</h1>


        <section className="landing-banner">
          <Link to="/ads" className="banner-link relative z-10">
            <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Ads</h2>
            <p></p>
          </Link>

          <div className="absolute z-0 left-0 top-1/4 -translate-y-1/4  w-full group-hover:scale-110 transform transition duration-300 "><StaticImage layout="fullWidth"  src="../assets/images/ads/ads-hero.jpg" alt="original 1994 Talman magazine ad next to a '94 TV750 Talman" quality={75}  /></div>
        </section>

        <section className="landing-banner">
          <Link to="/catalogs" className="banner-link relative z-10">
            <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Catalogs</h2>
            <p></p>
          </Link>

          <div className="absolute z-0 left-0 top-0 w-full group-hover:scale-110 transform transition duration-300 "><StaticImage layout="fullWidth" src="../assets/images/catalogs/catalogs-banner.jpg" alt="1998 Ibanez catalog open to Talman pages" quality={75}  /></div>
        </section>

        <section className="landing-banner">
          <Link to="/magazines" className="banner-link relative z-10">
          <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Magazines</h2>
          <p></p>
          </Link>

          <div className="absolute z-0 left-0 top-1/4 -translate-y-1/4  w-full group-hover:scale-110 transform transition duration-300 "><StaticImage layout="fullWidth" src="../assets/images/magazines/magazine-banner.jpg" alt="Talman headstock resting on top of a stack of guitar magazines" quality={75}  /></div>
        </section>

        <section className="landing-banner">
          <Link to="/video" className="banner-link relative z-10">
          <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Video</h2>
          <p></p>
          </Link>

          <div className="absolute z-0 left-0 top-1/2 -translate-y-1/2  w-full group-hover:scale-110 transform transition duration-300 "><StaticImage layout="fullWidth" src="../assets/images/video/video-banner.jpg" alt="still frame of Talman video featuring Gary Jannaman" quality={75}  /></div>
        </section>
      </div>
    </Layout>
  )
}

export default MediaPage